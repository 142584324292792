/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import AuthProvider from '../auth-provider/auth-provider';
import OverlayProvider from '../overlay-provider/overlay-provider';
import classNames from 'classnames';
import Header from '../header';

// Global Styles.
import '../../global/fonts/fonts.scss';
import '../../global/global.scss';

// Default layout styles.
import styles from './page-layout.module.scss';

const PageLayout = ({ children, layout, path, type }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const classes = {
    [styles.pageContainer]: true,
    [styles.pageContainerFull]: layout === 'full'
  };

  return (
    <AuthProvider>
      <OverlayProvider>
        <div
          className={
            type === 'search' ? styles.searchPageWrapper : styles.pageWrapper
          }
        >
          <Header siteTitle={data.site.siteMetadata.title} path={path} />
          <main className={classNames(classes)}>{children}</main>
        </div>
      </OverlayProvider>
    </AuthProvider>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.string,
  path: PropTypes.string,
  type: PropTypes.string
};

PageLayout.defaultProps = {
  layout: `standard`,
  path: ``
};

export default PageLayout;
