import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './form-error-message.module.scss';

const FormErrorText = ({ children, className }) => {
  return <p className={classnames(styles.error, className)}>{children}</p>;
};

export default FormErrorText;

FormErrorText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.object
};
