import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './button.module.scss';
import Link from '../link/link';
/**
 * The button component will return a single HTML <button>, <a>,
 * or a <Link> component.
 *
 * @returns {JSX} A button or link.
 */
const Button = ({
  buttonContent,
  children,
  className,
  color,
  size,
  withIcon = false,
  disabled,
  onClick,
  onFocus,
  textStyle,
  to,
  ...props
}) => {
  const classes = classnames(styles.button, {
    [styles[color]]: !disabled,
    [styles[size]]: size,
    [styles.withIcon]: withIcon,
    [styles.disabled]: disabled,
    [styles[textStyle]]: textStyle,
    [className]: className
  });

  if (to) {
    return (
      <Link disabled={disabled || false} className={classes} to={to}>
        {children || buttonContent}
      </Link>
    );
  }

  return (
    <button
      onClick={onClick}
      onFocus={onFocus}
      disabled={disabled || false}
      className={classes}
      {...props}
    >
      {children || buttonContent}
    </button>
  );
};

Button.propTypes = {
  buttonContent: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'white', 'transparent', false]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  /* Gives the button the style of a text link */
  textLink: PropTypes.bool,
  textStyle: PropTypes.oneOf(['upper', 'bold', false]),
  to: PropTypes.string
};

export default Button;
