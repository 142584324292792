import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import firebase from 'gatsby-plugin-firebase';
import FormErrorMessage from '../form-error-message';
import TextInput from '../text-input';
import Button from '../button';
// Form styles.
import styles from './password-reset-form.module.scss';
import { AuthContext } from '../auth-provider/auth-provider';
import { useLocation } from '@reach/router';

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Minimum eight characters, at least one letter and one number
const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

const PasswordResetForm = ({ exitClick, showLogin }) => {
  const [passwordResetError, setPasswordResetError] = useState('');
  const [passwordResetInfo, setPasswordResetInfo] = useState('');
  const [formState, { email, password, text }] = useFormState();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { mode, actionCode } = useContext(AuthContext);
  const location = useLocation();

  // send password reset email
  const sendPasswordResetEmail = async (increaseStep = true) => {
    try {
      await firebase.auth().sendPasswordResetEmail(formState.values.email, {
        url: location.origin,
        handleCodeInApp: true
      });
      if (increaseStep) {
        setCurrentStep((cs) => ++cs);
      }
    } catch (error) {
      setPasswordResetError(error.message);
    }
  };

  // verify password reset code
  const verifyPasswordResetCode = async () => {
    try {
      const email = await firebase
        .auth()
        .verifyPasswordResetCode(formState.values.code);
      formState.setField('email', email);
      setCurrentStep((cs) => ++cs);
    } catch (error) {
      setPasswordResetError(error.message);
    }
  };

  // confirm password reset
  const confirmPasswordReset = async () => {
    try {
      await firebase
        .auth()
        .confirmPasswordReset(formState.values.code, formState.values.password);
      exitClick();
      showLogin();
    } catch (error) {
      setPasswordResetError(error.message);
    }
  };

  const resendPasswordResetEmail = async () => {
    await sendPasswordResetEmail(false);
    setPasswordResetInfo('A new code has been sent to your email.');
  };

  const fieldNotEmpty = (field = '') => {
    return field.trim().length > 0;
  };

  const emailValidate = (value) => {
    if (!fieldNotEmpty) {
      return 'Email is required';
    }
    if (!EMAIL_REGEX.test(String(value).toLowerCase())) {
      return 'Not a valid email';
    }
  };

  const passwordValidate = (value) => {
    if (!fieldNotEmpty) {
      return 'Password is required';
    }
    if (!PASSWORD_REGEX.test(String(value))) {
      return 'Not a valid password';
    }
  };

  const reEnterPasswordValidate = (value) => {
    let result = passwordValidate(value);
    if (formState.values.password !== value) {
      result = 'Passwords do not match';
    }
    return result;
  };

  const handleSubmit = async (e, reqFunc) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      await reqFunc();
    } catch (error) {
      setPasswordResetError(error.message);
    }

    setIsLoading(false);
  };

  const steps = [
    {
      title: 'Password assistance',
      instruction:
        'Enter the email address associated with your New Bagehot Project account.',
      onSubmit: {
        onClick: sendPasswordResetEmail,
        disabled:
          formState.pristine['email'] === true ||
          formState.validity['email'] === false
      },
      fields: [
        {
          id: 'email',
          label: 'Email Address',
          formStateProps: {
            ...email({
              name: 'email',
              validateOnChange: true,
              validate: emailValidate
            })
          },
          valid: formState.validity['email'],
          message: formState.errors['email']
        }
      ]
    },
    {
      title: 'Verification required',
      instruction:
        "To continue, complete this verification step. We've sent a One Time Password (OTP) to the email username@email.com. Please enter it below.",
      onSubmit: {
        onClick: verifyPasswordResetCode,
        disabled:
          formState.pristine['code'] === true ||
          formState.validity['code'] === false
      },
      fields: [
        {
          id: 'code',
          label: 'One Time Password',
          formStateProps: {
            ...text({
              name: 'code',
              validateOnChange: true,
              validate: fieldNotEmpty
            })
          },
          valid: formState.validity['code'],
          message: formState.errors['email']
        }
      ],
      links: [
        {
          label: 'Resend OTP',
          onClick: resendPasswordResetEmail
        }
      ]
    },
    {
      title: 'Create new password',
      instruction:
        "To continue, complete this verification step. We've sent a One Time Password (OTP) to the email username@email.com. Please enter it below.",
      notes: (
        <div className={styles.passwordAssistanceNotes}>
          <div>
            <p>Secure password tips:</p>
            <ul>
              <li>
                Use at least 8 characters, a combination of numbers and letters
                is best.
              </li>
              <li>
                Do not use the same password you have used with us previously.
              </li>
              <li>
                Do not use dictionary words, your name, e-mail address, mobile
                phone number or other personal information that can be easily
                obtained.
              </li>
              <li>
                Do not use the same password for multiple online accounts.
              </li>
            </ul>
          </div>
        </div>
      ),
      onSubmit: {
        label: 'Save changes and sign-in',
        onClick: confirmPasswordReset,
        disabled:
          formState.pristine['password'] === true ||
          formState.validity['password'] === false ||
          formState.pristine['reEnterPassword'] === true ||
          formState.validity['reEnterPassword'] === false
      },
      fields: [
        {
          id: 'password',
          label: 'New Password',
          formStateProps: {
            ...password({
              name: 'password',
              validateOnChange: true,
              validate: passwordValidate
            })
          },
          note: 'Passwords must be at least 8 characters.',
          valid: formState.validity['password'],
          message: formState.errors['password']
        },
        {
          id: 're-enter-password',
          label: 'Re-Enter Password',
          formStateProps: {
            ...password({
              name: 'reEnterPassword',
              validateOnChange: true,
              validate: reEnterPasswordValidate
            })
          },
          valid: formState.validity['reEnterPassword'],
          message: formState.errors['reEnterPassword']
        }
      ]
    }
  ];

  useEffect(() => {
    switch (mode) {
      case 'resetPassword':
        formState.setField('code', actionCode || '');
        setCurrentStep(1);
        break;
      default:
    }
  }, [mode, actionCode, formState]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.closeButtonWrapper}>
        <Button
          color="white"
          onClick={exitClick}
          className={styles.closeButton}
        >
          <span className={styles.closeButtonText}>
            Close password reset form
          </span>
          <span></span>
          <span></span>
        </Button>
      </div>

      {steps?.map(
        ({ fields, title, instruction, onSubmit, notes, links }, i) =>
          currentStep === i && (
            <Fragment key={i}>
              <div className={styles.formHeaderContainer}>
                <h1 className={styles.formHeader}>{title}</h1>
                <p className={styles.instructions}>{instruction}</p>
              </div>
              <div className={styles.innerContainer}>
                <form
                  className={styles.form}
                  onSubmit={(e) => {
                    handleSubmit(e, onSubmit?.onClick);
                  }}
                >
                  {passwordResetError && (
                    <FormErrorMessage>{passwordResetError}</FormErrorMessage>
                  )}
                  <div className={styles.formFields}>
                    {fields?.map(
                      (
                        { id, label, formStateProps, valid, message, note },
                        index
                      ) => (
                        <Fragment key={index}>
                          <TextInput
                            className={styles.passwordResetInput}
                            id={id}
                            label={label}
                            labelSize="small"
                            maxLength={80}
                            required
                            formStateProps={formStateProps}
                            valid={valid}
                            message={message}
                          />
                          {note && (
                            <p className={styles.passwordResetInputNote}>
                              {note}
                            </p>
                          )}
                        </Fragment>
                      )
                    )}
                    {passwordResetInfo && (
                      <FormErrorMessage className={styles.passwordResetInfo}>
                        {passwordResetInfo}
                      </FormErrorMessage>
                    )}
                    <Button
                      color="blue"
                      type="submit"
                      name="submit"
                      className={styles.submitButton}
                      disabled={onSubmit?.disabled || isLoading}
                    >
                      {onSubmit?.label || 'Continue'}
                    </Button>

                    {links?.map(({ label, onClick }, i) => (
                      <Button
                        className={styles.passwordResetLink}
                        key={i}
                        onClick={onClick}
                      >
                        {label}
                      </Button>
                    ))}
                  </div>
                </form>
              </div>
              {notes}
            </Fragment>
          )
      )}
    </div>
  );
};

export default PasswordResetForm;
