import React, { useState } from 'react';

export const CompareStateContext = React.createContext(null);

const CompareStateProvider = ({ children }) => {
  const [compareState, setCompareArticles] = useState({});

  const getCompareCount = () => Object.keys(compareState).length;

  const addCompareArticle = (id) =>
    setCompareArticles((prevCompareArticles) => {
      return {
        ...prevCompareArticles,
        [id]: true
      };
    });

  const removeCompareArticle = (id) =>
    setCompareArticles((prevCompareArticles) => {
      const newCompareArticles = { ...prevCompareArticles };
      delete newCompareArticles[id];
      return newCompareArticles;
    });

  const clearCompareArticles = () => setCompareArticles({});

  const buildCompareUrl = () =>
    `/compare/${Object.keys(compareState).join(',')}`;

  const mayAddArticle = () => getCompareCount() < 6;

  return (
    <CompareStateContext.Provider
      value={{
        compareState,
        addCompareArticle,
        removeCompareArticle,
        clearCompareArticles,
        buildCompareUrl,
        mayAddArticle,
        getCompareCount
      }}
    >
      {children}
    </CompareStateContext.Provider>
  );
};

export default CompareStateProvider;
