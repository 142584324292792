import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../auth-provider';
import firebase from 'gatsby-plugin-firebase';
import classNames from 'classnames';
import LoginForm from '../login-form/login-form';
import Modal from 'react-modal';
import Link from '../link';
import Button from '../button';
import styles from './user-menu.module.scss';
import { navigate } from 'gatsby';
import PasswordResetForm from '../password-reset-form/password-reset-form';

Modal.setAppElement('#___gatsby');

const UserMenu = ({ path }) => {
  const { currentUser, isInit, mode, setMode, setActionCode } = useContext(
    AuthContext
  );
  const [showLogin, setLogin] = useState(false);
  const [isPasswordReset, setPasswordReset] = useState(false);
  const [showUserMenu, setUserMenu] = useState(false);

  const hideLogin = () => {
    setLogin(false);
  };

  const showPasswordReset = useCallback(() => {
    if (currentUser) {
      navigate('/');
      return;
    }

    setPasswordReset(true);
    hideLogin();
  }, [currentUser]);

  const hidePasswordReset = () => {
    setMode('');
    setActionCode('');
    setPasswordReset(false);
    navigate('/');
  };

  const handleLogout = () => {
    firebase.auth().signOut();
    navigate('/');
  };

  const userClasses = {
    [styles.userSettingsLink]: true,
    [styles.userSettingsLinkActive]: path === 'account'
  };

  useEffect(() => {
    if (isInit) {
      switch (mode) {
        case 'resetPassword':
          showPasswordReset();
          break;
        default:
      }
    }
  }, [mode, showPasswordReset, isInit]);

  if (!isInit) {
    return null;
  }

  return (
    <>
      {!currentUser ? (
        <>
          {!showLogin && (
            <Button
              color="blue"
              type="submit"
              name="submit"
              size={'small'}
              className={styles.loginButton}
              onClick={() => setLogin(!showLogin)}
            >
              Log in
            </Button>
          )}
          {showLogin && (
            <Modal
              isOpen={showLogin}
              contentLabel="Login Form"
              onRequestClose={() => hideLogin()}
              shouldCloseOnOverlayClick={true}
              className={styles.loginModal}
              overlayClassName={styles.loginModalOverlay}
              showPasswordReset={showPasswordReset}
            >
              <LoginForm
                exitClick={() => hideLogin()}
                showPasswordReset={showPasswordReset}
              />
            </Modal>
          )}
          {isPasswordReset && (
            <Modal
              isOpen={isPasswordReset}
              contentLabel="Password Reset Form"
              onRequestClose={hidePasswordReset}
              shouldCloseOnOverlayClick={true}
              className={styles.loginModal}
              overlayClassName={styles.loginModalOverlay}
            >
              <PasswordResetForm
                exitClick={hidePasswordReset}
                showLogin={() => setLogin(true)}
              />
            </Modal>
          )}
        </>
      ) : (
        <div className={styles.account}>
          <Button
            color="transparent"
            onClick={() => setUserMenu(!showUserMenu)}
            className={styles.usernameDisplay}
            aria-expanded={!showUserMenu ? 'false' : 'true'}
            aria-controls={'user-menu-options'}
          >
            {currentUser.firstName + ' ' + currentUser.lastName}
          </Button>
          <ul
            className={styles.userDropdown}
            id={'user-menu-options'}
            aria-hidden={!showUserMenu ? 'true' : 'false'}
          >
            <li className={styles.dropdownOption}>
              <Link className={classNames(userClasses)} to="/my-account">
                User Settings
              </Link>
            </li>
            <li className={styles.dropdownOption}>
              <Button
                className={styles.logoutLink}
                color={'transparent'}
                size={'small'}
                onClick={handleLogout}
              >
                Log out
              </Button>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

UserMenu.propTypes = {
  path: PropTypes.string
};

UserMenu.defaultProps = {
  path: ``
};

export default UserMenu;
