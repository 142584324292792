import React, { useState } from 'react';

export const OverlayContext = React.createContext();

const OverlayProvider = ({ children }) => {
  const [active, setOverlayActive] = useState(false);

  return (
    <OverlayContext.Provider
      value={{ overlayActive: active, setOverlayActive }}
    >
      {children}
      {active && <div>OVERLAY</div>}
    </OverlayContext.Provider>
  );
};

export default OverlayProvider;
