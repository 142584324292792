import React, { useState } from 'react';
import { useFormState } from 'react-use-form-state';
import firebase from 'gatsby-plugin-firebase';
import FormErrorMessage from '../form-error-message';
import TextInput from '../text-input';
import Button from '../button';
// Form styles.
import styles from './login-form.module.scss';

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const LoginForm = ({ exitClick, showPasswordReset }) => {
  const [loginError, setLoginError] = useState('');
  const [formState, { email, password }] = useFormState();

  const fieldNotEmpty = (field = '') => {
    return field.trim().length > 0;
  };

  const emailValidate = (value) => {
    if (!fieldNotEmpty) {
      return 'Email is required';
    }
    if (!EMAIL_REGEX.test(String(value).toLowerCase())) {
      return 'Not a valid email';
    }
  };

  // The required fields for this form.
  const required = ['email', 'password'];

  const handleSubmit = async (e) => {
    const allRequiredFieldsFilled = required.every((field) =>
      fieldNotEmpty(formState.values[field])
    );
    e.preventDefault();
    if (!allRequiredFieldsFilled) {
      e.preventDefault(); // Prevent submission if fields are invalid.
      // Set all required fields that are missing values to invalid.
      required.forEach((field) => {
        if (!fieldNotEmpty(formState.values[field])) {
          formState.validity[field] = false;
          formState.setFieldError(formState.values[field], 'This is required');
        }
      });
      return false;
    } else {
      // If all fields are valid, return true and continue with submission.
      const validatedFields = Object.keys(formState.validity);
      validatedFields.forEach((key) => {
        if (!formState.validity[key]) {
          formState.setFieldError(key, `Invalid field for ${key}`);
        }
      });
    }
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(
          formState.values.email,
          formState.values.password
        );
    } catch (error) {
      setLoginError(error.message);
    }
  };

  return (
    <div className={styles.outerContainer}>
      <div className={styles.closeButtonWrapper}>
        <Button
          color="white"
          onClick={exitClick}
          className={styles.closeButton}
        >
          <span className={styles.closeButtonText}>Close login form</span>
          <span></span>
          <span></span>
        </Button>
      </div>
      <h1 className={styles.formHeader}>Log in to The New Bagehot Project</h1>
      <div className={styles.innerContainer}>
        <form
          className={styles.form}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {loginError && <FormErrorMessage>{loginError}</FormErrorMessage>}
          <div className={styles.formFields}>
            <TextInput
              className={styles.loginInput}
              id="email"
              label="Email Address"
              labelSize="small"
              maxLength={80}
              required
              formStateProps={{
                ...email({
                  name: 'email',
                  validateOnChange: true,
                  validate: emailValidate
                })
              }}
              valid={formState.validity['email']}
              message={formState.errors['email']}
            />
            <TextInput
              className={styles.loginInput}
              id="password"
              label="Password"
              labelSize="small"
              required
              maxLength={80}
              formStateProps={{
                ...password({
                  name: 'password',
                  validateOnChange: true,
                  validate: fieldNotEmpty
                })
              }}
              valid={formState.validity['password']}
            />
            <Button
              className={styles.passwordResetLink}
              onClick={() => showPasswordReset()}
              type="button"
            >
              Forgot your password?
            </Button>
            <Button
              color="blue"
              type="submit"
              name="submit"
              className={styles.submitButton}
              disabled={
                formState.pristine['email'] === true ||
                formState.pristine['password'] === true ||
                formState.validity['email'] === false ||
                formState.validity['password'] === false
              }
            >
              Log in
            </Button>
          </div>
        </form>
      </div>
      <p className={styles.loginDisclaimer}>
        For assistance with your log-in credentials, please email{' '}
        <a href="mailto:christian.mcnamara@yale.edu">Christian McNamara</a>
      </p>
    </div>
  );
};

export default LoginForm;
