import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import UserMenu from '../user-menu/user-menu';
import Search from './assets/search-icon.svg';
import Button from '../button';
import { AuthContext } from '../auth-provider';

// Header styles.
import styles from './header.module.scss';

const Header = ({ siteTitle, path }) => {
  const [subSection, showSubSection] = useState(false);
  const { showConfirmation } = useContext(AuthContext);
  const searchClasses = {
    [styles.searchIcon]: true,
    [styles.searchActive]: path === 'search'
  };

  const aboutClasses = {
    [styles.aboutLink]: true,
    [styles.aboutActive]: path === 'about'
  };

  return (
    <header className={styles.headerWrapper}>
      <nav className={styles.headerContainer}>
        <h1 className={styles.headerTitle}>
          <Link to="/">{siteTitle}</Link>
        </h1>
        <div className={styles.headerRightItems}>
          <div className={styles.headerMenu}>
            <Link className={styles.searchWrapper} to="/search">
              <span className={styles.searchText}>Search</span>
              <Search className={classNames(searchClasses)} />
            </Link>
            <div className={styles.headerMenuSection}>
              <Button
                className={styles.headerMenuSectionToggle}
                onClick={() => showSubSection(!subSection)}
                aria-controls={'header-menu-sub-section'}
                aria-expanded={!subSection ? 'false' : 'true'}
              >
                <span className={styles.menuText}>Menu</span>
                <span className={styles.menuBars}>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </Button>
              <div
                id={'header-menu-sub-section'}
                data-hidden={!subSection ? 'true' : 'false'}
                className={styles.headerMenuSubSection}
              >
                <Link className={classNames(aboutClasses)} to="/about/project/">
                  About
                </Link>
                <UserMenu path={path} />
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className={showConfirmation ? styles.headerMessageArea : ''}>
        {showConfirmation ? 'Successfully logged in' : ''}
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  path: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``,
  path: ``
};

export default Header;
