import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles, {
  wrapper,
  floated,
  input,
  error,
  errorMessage
} from './text-input.module.scss';

const TextInput = ({
  className,
  disabled,
  formStateProps = {},
  helpText,
  hidden,
  id,
  label,
  labelSize,
  labelOrder = 'before',
  maxLength,
  message = 'This field is required.',
  pattern,
  patternMessage,
  required,
  type,
  valid = true
}) => {
  const wrapperClass = classnames(wrapper, {
    [floated]: formStateProps.value, // If a value exists, float the label.
    [error]: !valid,
    [styles.required]: required,
    [styles.disabled]: disabled,
    [styles[label]]: label,
    [className]: className
  });

  const labelClass = classnames(label, {
    [styles[labelSize]]: labelSize
  });

  return (
    <div className={wrapperClass}>
      {labelOrder === 'before' && (
        <label
          id={helpText && `labelledby-label-${id}`}
          className={labelClass}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <input
        {...formStateProps}
        aria-describedby={!valid ? `error-${id}` : null}
        aria-labelledby={
          helpText ? `labelledby-label-${id} labelledby-help-${id}` : null
        }
        aria-invalid={!valid}
        aria-required={required}
        className={input}
        disabled={disabled}
        maxLength={maxLength || null}
        id={id}
        pattern={pattern || null}
        title={(pattern && patternMessage) || null}
        type={type || formStateProps.type || 'text'}
        hidden={hidden || null}
      />
      {labelOrder === 'after' && (
        <label
          id={helpText && `labelledby-label-${id}`}
          className={labelClass}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {/* Include an error message. */}
      {message && (
        <div id={`error-${id}`} className={errorMessage} aria-hidden={valid}>
          {message}
        </div>
      )}
      {helpText && (
        <span className={styles.helpText} id={`labelledby-help-${id}`}>
          {helpText}
        </span>
      )}
    </div>
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  formStateProps: PropTypes.shape({
    checked: PropTypes.bool,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.string
  }),
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelSize: PropTypes.oneOf(['large', 'small', false]),
  labelOrder: PropTypes.oneOf(['after', 'before']),
  /* Limits the number of characters that can be entered in a field. */
  maxLength: PropTypes.number,
  message: PropTypes.string,
  /* A regex string for validating the input. */
  pattern: PropTypes.string,
  /* A message to display describing the pattern validation. Will display
     in the HTML5 error message */
  patternMessage: PropTypes.string,
  helpText: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  valid: PropTypes.bool
};

export default TextInput;
