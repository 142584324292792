import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './link.module.scss';

import { Link as GatsbyLink } from 'gatsby';
/**
 * A Link component that can accept an external url or internal path.
 *
 * Will return a Gatsby <Link> if the path is internal or a regular
 * <a href=""> if the path is external.
 *
 * @param {JSX} children The children passed into the component.
 * @param {string} className The class names passed into the component.
 * @param {bool} disabled Whether or not the link is disabled.
 * @param {func} onClick A click handler function.
 * @param {string} to A path or url.
 * @returns {JSX} A <Link> or <a href="">
 */
const Link = ({ children, className, disabled, onClick, to, ...props }) => {
  // If the `to` prop has no protocol,
  // return a Gatsby <GatsbyLink> component.
  const classes = classnames(styles.link, {
    [className]: className
  });
  if (to && to.indexOf('http') === -1 && to.indexOf('tel:') === -1) {
    return (
      <GatsbyLink
        onClick={onClick}
        disabled={disabled || false}
        className={classes}
        to={to}
        {...props}
      >
        {children}
      </GatsbyLink>
    );
  }
  // If the `to` property exists and has a protocol,
  // return an HTML <a href="">.
  else if (to && (to.indexOf('http') !== -1 || to.indexOf('tel:') !== -1)) {
    return (
      <a
        disabled={disabled || false}
        className={className}
        href={to}
        onClick={onClick}
        {...props}
      >
        {children}
      </a>
    );
  }

  return null;
};

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired
};

export default Link;
